import logo from "./logo.svg";
import "./App.css";
import AdminPanel from "./AdminPanel";
import "bootstrap/dist/css/bootstrap.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { ToastContainer } from "react-toastify";
import { network } from "./utils/contants";
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "61d2c1fda6f35b603e3367b36447aa0a";

// 3. Create modal
const metadata = {
  name: "Admin - Electric Doge",
  description: "ElectricDoge management panel",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [network],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
function App() {
  return (
    <div>
      <AdminPanel />
      <ToastContainer />
    </div>
  );
}

export default App;
