// src/components/AdminPanel.js
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Col,
  Dropdown,
  Row,
  InputGroup,
  Container,
  Card,
  Badge,
} from "react-bootstrap";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import DateTimePicker from "react-datetime-picker";
import { useTranslation } from "react-i18next";
import {
  erc20Abi,
  presaleAbi,
  presaleAddress,
  tokenAddress,
  usdtAddresss,
} from "./utils/contants";
import { toast } from "react-toastify";
import { Contract, ethers } from "ethers";
import Big from "big.js";

const AdminPanel = () => {
  const { t, i18n } = useTranslation();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [web3, setWeb3] = useState(null);
  const [presaleContract, setPresaleContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);

  const [bnbBalance, setBNBBalance] = useState("0");
  const [usdtBalance, setUSDTBalance] = useState("0");
  const [tokenBalance, setTokenBalance] = useState("0");
  const [soldTokenAmount, setSoldTokenAmount] = useState("0");

  const [depositAmountToken, setDepositAmountToken] = useState("0");
  const [withdrawAmountToken, setWithDrawAmountToken] = useState("0");

  const [withdrawAmountUsdt, setWithdrawAmountUsdt] = useState("0");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [roundAmount, setRoundAmount] = useState(0);
  const [roundRate, setRoundRate] = useState(0);
  const [ownerAddress, setOwnerAddress] = useState("");

  const [salePaused, setSalePaused] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const init = async () => {
    try {
      if (!isConnected) return;
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const contract = new Contract(presaleAddress, presaleAbi, signer);
      setPresaleContract(contract);
      const _tokenContract = new Contract(tokenAddress, erc20Abi, signer);
      setTokenContract(_tokenContract);
    } catch (error) {
      console.log(error);
      toast.info(t("info.error"));
    }
  };
  const getFirstPart = (num) => {
    if (num.length <= 18) {
      return "0"
    }
    else {
      return num.toString().substr(0, num.length - 18);
    }
  }
  const getLastPart = (num) => {
    if (num.length <= 18) {
      return num.padStart(18, '0')
    }
    else {
      return num.toString().substr(num.length - 18, 18)
    }
  }

  const getFirstPartUSDT = (num) => {
    if (num.length <= 18) {
      return "0"
    }
    else {
      return num.toString().substr(0, num.length - 18);
    }
  }
  const getLastPartUSDT = (num) => {
    if (num.length <= 18) {
      return num.padStart(18, '0')
    }
    else {
      return num.toString().substr(num.length - 18, 18)
    }
  }
  const getPrice = () => {
    if (roundRate === 0) return "0"
    return (Number(100000 / roundRate).toFixed(10)).toString()
  }
  const fetchBalances = async () => {
    try {
      let _bnbBal = await presaleContract.getBNBBalance();
      setBNBBalance(_bnbBal.toString());
      let _usdtBal = await presaleContract.getUsdtBalance();
      setUSDTBalance(_usdtBal.toString());
      let _tokenBal = await presaleContract.getTokenBalance();
      setTokenBalance(_tokenBal.toString());
      let _soldAmount = await presaleContract.totalTokensSold();
      setSoldTokenAmount(_soldAmount.toString());
    } catch (error) {
      console.log("Error fetching balances", error);
    }
  };
  const fetchDetails = async () => {
    try {
      let _roundDetails = await presaleContract.rounds(0);
      setRoundAmount(_roundDetails[0].toString());
      setRoundRate(_roundDetails[1].toString());
      let _startTime = await presaleContract.startTime();
      setStartDate(new Date(Number(_startTime) * 1000));
      let _endTime = await presaleContract.endTime();
      setEndDate(new Date(Number(_endTime) * 1000));
      let _isPaused = await presaleContract.paused();
      setSalePaused(_isPaused);
    } catch (error) {
      console.log(error);
    }
  };
  const updateStartDate = async () => {
    try {
      console.log(startDate.getTime() / 1000);
      let result = await presaleContract.changeSaleStartTime(
        parseInt(startDate.getTime() / 1000).toString()
      );
      toast.success(t("info.sent"));
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const updateEndDate = async () => {
    try {
      let result = await presaleContract.changeSaleEndTime(
        parseInt(endDate.getTime() / 1000).toString()
      );
      toast.success(t("info.sent"));
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const withdrawBnb = async () => {
    try {
      let result = await presaleContract.withdrawBNB();
      toast.success(t("info.sent"));
      fetchBalances();
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const withdrawUsdt = async () => {
    try {
      let result = await presaleContract.withdrawTokens(
        usdtAddresss,
        withdrawAmountUsdt
      );
      toast.success(t("info.sent"));
      fetchBalances();
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const withdrawTokens = async () => {
    try {
      let result = await presaleContract.withdrawTokens(
        tokenAddress,
        withdrawAmountToken
      );
      toast.success(t("info.sent"));
      fetchBalances();
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const depositTokens = async () => {
    try {
      if (!tokenContract) {
        console.log("Contract not intialized");
        return;
      }
      let result = await tokenContract.transfer(
        presaleAddress,
        depositAmountToken
      );
      if (result) {
        toast.success(t("info.sent"));
      }
      fetchBalances();
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const updateRoundDetails = async () => {
    try {
      if (!presaleContract) {
        return;
      }
      let result = await presaleContract.updateRounds(
        0,
        roundAmount.toString(),
        roundRate.toString()
      );
      if (result) {
        toast.success(t("info.sent"));
      }
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const transferOwnership = async () => {
    try {
      if (!presaleContract) {
        return;
      }
      if (!ownerAddress) {
        toast.info(t("info.new_address"));
        return;
      }
      let result = await presaleContract.transferOwnership(
        ownerAddress.toString()
      );
      if (result) {
        toast.success("info.sent");
      }
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const pauseSale = async () => {
    try {
      // if (!presaleContract) {
      //   return;
      // }
      let result = await presaleContract.pause();
      if (result) {
        toast.success("info.sent");
      }
      fetchDetails();
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  const resumeSale = async () => {
    try {
      if (!presaleContract) {
        return;
      }
      let result = await presaleContract.unpause();
      if (result) {
        toast.success("info.sent");
      }
    } catch (error) {
      console.log(error);
      toast.info(error.toString().substring(0, 100) + "...");
    }
  };
  useEffect(() => {
    if (!presaleContract) return;
    fetchDetails();
    fetchBalances();
  }, [presaleContract]);

  useEffect(() => {
    init();
  }, [chainId]);
  return (
    <div
      className="container"
      style={{
        padding: "10px",
      }}
    >
      <div
        className=" container d-flex justify-content-between align-items-center"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <h2 className="mt-4">{t("adminPanel.title")}</h2>
        <div className="d-flex" style={{ gap: "10px" }}>
          <w3m-button />

          <Dropdown>
            <Dropdown.Toggle variant="info" id="languageDropdown">
              Language
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item key={"en"} onClick={() => changeLanguage("en")}>
                {"English"}
              </Dropdown.Item>
              <Dropdown.Item key={"ko"} onClick={() => changeLanguage("ko")}>
                {"Korean"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Container className="mt-4 mb-4">
        <h4>{t("adminPanel.statistics")}</h4>
        <Row xs={1} md={2} lg={3} className="g-4">

          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.tokenBalance")}</Card.Title>
                <h2>{getFirstPart(tokenBalance)}</h2>
                <h2>{"."}{getLastPart(tokenBalance)}</h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.usdtBalance")}</Card.Title>
                <h2>{getFirstPartUSDT(usdtBalance)}</h2>
                <h2>{"."}{getLastPartUSDT(usdtBalance)}</h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.bnbBalance")}</Card.Title>
                <h2>{getFirstPart(bnbBalance)}</h2>
                <h2>{"."}{getLastPart(bnbBalance)}</h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.tokens_sold")}</Card.Title>
                <h2>{getFirstPart(soldTokenAmount)}</h2>
                <h2>{"."}{getLastPart(soldTokenAmount)}</h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.token_rate")}</Card.Title>
                <h2>

                  {getPrice()}
                  {"$"}{" "}
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {"(" + roundRate + ")"}
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.startDate")}</Card.Title>
                <p>{startDate.toLocaleDateString()}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.endDate")}</Card.Title>
                <p>{endDate.toLocaleDateString()}</p>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>{t("adminPanel.target_token")}</Card.Title>
                <h2>{roundAmount}</h2>
              </Card.Body>
            </Card>
          </Col> */}
          <Col md={6} lg={4}>
            <Card>
              <Card.Body>
                <Card.Title>
                  {t("adminPanel.sale_status")}{" "}
                  <Badge bg={salePaused ? "danger" : "success"}>
                    {salePaused ? "Paused" : "Active"}
                  </Badge>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <h4 className="mt-4 mb-4">Controls</h4>
      <Row>
        <Col>
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="card-title">{t("adminPanel.startDate")}</h4>
              <DateTimePicker onChange={setStartDate} value={startDate} />
              <br />
              <Button
                className="mt-3 "
                variant="primary"
                onClick={updateStartDate}
              >
                {t("adminPanel.changeStartDate")}
              </Button>
            </div>
          </div>
        </Col>
        <Col>
          {" "}
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="card-title">{t("adminPanel.endDate")}</h4>
              <DateTimePicker onChange={setEndDate} value={endDate} />
              <br />
              <Button
                className="mt-3 "
                variant="primary"
                onClick={updateEndDate}
              >
                {t("adminPanel.changeEndDate")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card mb-3 ">
            <div className="card-body">
              <h4 className="card-title">
                {t("adminPanel.bnbBalance")} {getFirstPart(bnbBalance)}
              </h4>
              <h4>
                {"." + getLastPart(bnbBalance)}
              </h4>
              <br />
              <Button variant="primary" onClick={withdrawBnb}>
                {t("adminPanel.withdrawBNB")}
              </Button>
            </div>
          </div>
        </Col>{" "}
        <Col>
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="card-title">
                {t("adminPanel.usdtBalance")} {getFirstPartUSDT(usdtBalance)}
              </h4>
              <h4>
                {"." + getLastPartUSDT(usdtBalance)}
              </h4>
              <Form.Control
                type="number"
                value={withdrawAmountUsdt}
                onChange={(event) => {
                  setWithdrawAmountUsdt(event.target.value.toString());
                }}
              />
              <Button className="mt-3" variant="primary" onClick={withdrawUsdt}>
                {t("adminPanel.withdrawUSDT")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="card-title">{t("adminPanel.tokenBalance")}</h4>
              <h4>{getFirstPart(tokenBalance)}</h4>
              <h4>{"."}{getLastPart(tokenBalance)}</h4>
              <Row>
                <Col className="pt-3 pb-3">
                  <Form.Control
                    type="number"
                    placeholder="Deposit amount"
                    value={depositAmountToken}
                    onChange={(event) => {
                      setDepositAmountToken(event.target.value.toString());
                    }}
                  />
                  <br />
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={depositTokens}
                  >
                    {t("adminPanel.deposit")}
                  </Button>
                </Col>

                <Col className=" pt-3 pb-3">
                  <Form.Control
                    type="number"
                    placeholder="Withdraw amount"
                    value={withdrawAmountToken}
                    onChange={(event) => {
                      setWithDrawAmountToken(event.target.value.toString());
                    }}
                  />
                  <br />
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={withdrawTokens}
                  >
                    {t("adminPanel.withdraw")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>{" "}
      <div className="card mb-3">
        <div className=" card-body">
          <h4>{t("adminPanel.roundDetails")}</h4>
          <Form>
            {/* <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                {t("adminPanel.roundNo")}
              </Form.Label>
              <Col sm="10">
                <Form.Control type="number" defaultValue={0} />
              </Col>
            </Form.Group> */}

            {/* <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                {t("adminPanel.amount")}
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  value={roundAmount}
                  onChange={(event) => {
                    setRoundAmount(event.target.value.toString());
                  }}
                />
              </Col>
            </Form.Group> */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                {t("adminPanel.rate")}{" "}
                <span style={{ fontWeight: "normal", fontSize: "12px" }}>
                  {"(6666666=0.0015$)"}
                </span>
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  value={roundRate}
                  onChange={(event) => {
                    setRoundRate(event.target.value.toString());
                  }}
                />
              </Col>
            </Form.Group>
            <Button onClick={updateRoundDetails} variant="primary">
              {t("adminPanel.update")}
            </Button>
          </Form>
        </div>
      </div>
      <div className="card mb-3">
        <div className=" card-body">
          <h4>{t("adminPanel.changeOwnerShip")}</h4>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                {t("adminPanel.address")}
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  value={ownerAddress}
                  onChange={(event) => {
                    setOwnerAddress(event.target.value.toString());
                  }}
                />
              </Col>
            </Form.Group>

            <Button variant="primary" onClick={transferOwnership}>
              {t("adminPanel.transfer")}
            </Button>
          </Form>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <h4 className="">
            {t("adminPanel.sale_paused")} : {salePaused.toString()}
          </h4>
          <Button
            className="me-3"
            variant="danger"
            disabled={salePaused}
            onClick={pauseSale}
          >
            {t("adminPanel.stopSale")}
          </Button>
          <Button variant="success" disabled={!salePaused} onClick={resumeSale}>
            {t("adminPanel.unfreeze")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
